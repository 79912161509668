.app-container {
    margin: 10px;
}

.app-selector-div {
    margin-top: 10px; /* centers app mode selection icons */
}

.app-selector-icon {
    padding: 0 5px;
    margin: 0 6px;
}

.app-selector-disabled {
    color: lightgray;
}

.navbar-left {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.navbar {
    display: flex;
}

body[dir='ltr'] .navbar-header-title {
    padding-left: 10px;
}

.navbar-brand {
    color: #777;
}

.navbar-brand:focus,
.navbar-brand:hover {
    --bs-navbar-brand-hover-color: #777;
}

@media screen and (max-width: 600px) {
    .navbar {
        flex-direction: column-reverse;
    }
}

.navbar-header-icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.app-navbar-bottom-margin {
    margin-bottom: 10px;
}

/* always ltr */
.app-database {
    direction: ltr;
    display: flex;
}

.app-database-list {
    width: 50%;
    overflow-y: auto;
    min-height: 500px;
    height: 80vh;
    border-right: solid grey 1px; /* always ltr */
}

.app-database-details {
    flex-grow: 1;
    width: 50%;
    margin-left: 10px; /* always ltr */
    margin-top: 5px;
}

.project-name-box {
    margin-top: 5px;
}

.db-row-removed {
    color: lightgrey;
}

.registration-email input {
    width: 250px;
}

.initialization-message {
    display: flex;
    align-items: center;
}

.navbar-header-project {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    z-index: 500;
}

.project-selector-custom-select {
    width: 350px;
}

#react-confirm-alert .react-confirm-alert-overlay {
    z-index: 9999;
}

.offline-message {
    position: sticky;
    top: 0;
    z-index: 150;
    margin-bottom: 10px;
}

.message-warning {
    background-color: lightgray;
    padding: 5px;
    box-shadow: 0px 1px 8px 0px rgba(143, 143, 143, 1);
    display: flex;
    align-items: center;
}

input:read-only {
    border: 1px solid lightgray;
    background-color: rgb(246, 246, 246);
}

input:read-only:focus {
    outline: none;
}

textarea:focus {
    outline-color: #337ab7;
}

textarea:read-only {
    border: 1px solid lightgray;
}

textarea:read-only:focus {
    outline: none;
}

.top-navigation-icon {
    fill: #337ab7;
    color: #337ab7;
}

.top-navigation-icon:hover {
    fill: #23527c;
    color: #23527c;
}

.main-headset-icon {
    display: block;
    width: 28px;
    height: 28px;
}

.app-selector-disabled svg {
    fill: lightgray;
}

.error-link {
    color: white;
    text-decoration: underline;
}

.error-link:hover {
    color: black;
}

.database-query-area {
    direction: ltr;
    padding-bottom: 10px;
    border-bottom: 1px solid grey;
}

.database-row {
    padding: 10px;
}

.database-search-boxes {
    display: flex;
    gap: 10px;
}

.no-internet-text {
    color: rgb(119, 119, 119);
    white-space: nowrap;
}

.no-internet-icon {
    font-size: 100%;
    margin: 0 5px;
    flex-shrink: 0;
}

.sync-status-icon {
    font-size: 150%;
    color: tomato;
}

.search-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;
}

.search-icon {
    font-size: 150%;
}
.styled-dropdown-select {
    font-size: 10pt;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    text-shadow: 0 1px 0 #fff;
    padding: 3px 6px;
}

.styled-dropdown-select:after {
    margin: 0 0.255em;
}

.styled-dropdown-select:disabled {
    background-color: #e0e0e0;
    background-image: none;
    color: #333;
    border-color: #ccc;
}

.styled-dropdown-select:hover,
.styled-dropdown-select:focus {
    background-color: #e0e0e0;
    border-color: #adadad;
    color: #333;
    z-index: 2;
}

.styled-dropdown-select.btn.show {
    background-color: #e6e6e6;
    background-image: none;
    border-color: #adadad;
    color: #333;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.styled-dropdown-select:active {
    background-color: #d4d4d4 !important;
    border-color: #8c8c8c !important;
    color: #333 !important;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.styled-dropdown-select-menu {
    overflow-y: auto;
    max-height: 450px;
    color: #333;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    background-color: #fff;
    float: left;
    font-size: 14px;
    margin: 2px 0 0;
    min-width: 160px;
    padding: 5px 0;
    position: absolute;
    text-align: left;
    top: 100%;
    z-index: 1000;
}

body[dir='rtl'] .styled-dropdown-select-menu {
    text-align: right;
}

.styled-dropdown-select-menu a {
    font-weight: 400;
    line-height: 1.42857143;
    padding: 3px 20px;
}

.styled-dropdown-select-menu a:hover {
    background-color: #e8e8e8;
    background-image: linear-gradient(180deg, #f5f5f5 0, #e8e8e8);
    background-repeat: repeat-x;
    color: #262626;
}

.styled-dropdown-select-menu a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.styled-dropdown-select-menu .active a,
.styled-dropdown-select-menu a.active {
    background-color: #2e6da4;
    background-image: linear-gradient(180deg, #337ab7 0, #2e6da4);
    background-repeat: repeat-x;
    color: #fff;
    outline: 0;
    text-decoration: none;
}

.styled-primary-button {
    background-image: linear-gradient(180deg, #337ab7 0, #265a88);
    background-repeat: repeat-x;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
    color: #fff;
    border: 1px solid #245580;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    margin-bottom: 0;
    padding: 6px 12px;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
}

.styled-primary-button:focus,
.styled-primary-button:hover {
    background-color: #265a88;
    background-image: unset;
    transition-property: none;
}

.styled-primary-button:hover {
    border-color: #204d74;
    color: #fff;
}

.styled-primary-button:focus {
    border-color: #122b40;
    color: #fff;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px;
}

.styled-primary-button:active {
    background-color: #265a88 !important;
    border-color: #245580 !important;
    background-image: unset;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.styled-primary-button:disabled {
    opacity: 0.65;
    background-color: #265a88;
    background-image: none;
    border-color: #245580;
}

.sl-pane-close-button {
    font-size: 2rem;
    padding-top: 0px;
}

.modal-title {
    width: 100%;
}

.modal-title.passage-editor {
    width: unset;
}

body[dir='rtl'] .form-check .form-check-input {
    float: right;
    margin-right: 0em;
    margin-left: 0.5em;
}

body[dir='rtl'] .react-confirm-alert,
body[dir='rtl'] .react-confirm-alert-body {
    text-align: right;
}

/* multi-select RTL */
body[dir='rtl'] .rmsc .item-renderer input {
    margin-right: 0px;
    margin-left: 5px;
}

body[dir='rtl'] .rmsc .options {
    padding-left: inherit;
    padding-right: 0px;
}

/* Toast */
body[dir='rtl'] .Toastify__toast-container--top-right {
    top: 1em;
    right: auto;
    left: 1em;
}

body[dir='rtl'] .Toastify__toast--info {
    direction: rtl;
}

.projects-table-delete-button {
    margin-left: auto;
}

.projects-table-timestamp {
    white-space: nowrap;
    display: inline;
}
